import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const BonIver = () => {
  return (
    <Layout>
      <Head title="Bon Iver" />
      <h3>Bon Iver</h3>
      <h4>
        <FaGlobe />
        Eau Claire, Wisconsin
      </h4>
      <p>
        <OutboundLink href="https://boniver.org/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/boniver/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://twitter.com/boniver">Twitter</OutboundLink>
      </p>

      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/uOIWjlVM08s?si=xuuKc3yOrYz8_etm"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xQ1oYgN1jSg?si=K34y7OdeiXbh0LHu"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/D3MQDXq5FYk?si=_8P1zKWtHt4VFXDI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default BonIver;
